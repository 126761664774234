import React, { useState, useEffect } from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import dayjs from 'dayjs';
import _ from 'lodash';

import Yup from '../../utils/yup';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import useUsers from '../../context/UsersContext';
import attendenceService from '../../service/attendence.service';
import useMenus from '../../context/MenuContext';
import masterDataService from '../../service/masterData.service';
import { FormHeaderUI, FormButtonPanelUI, DeleteDialogUI, ButtonUI } from '../Interface';
import AttendanceField from './AttendanceFields';
import AttendanceReports from './AttendanceReports';
import AttendanceTabs from './AttendanceTabs';

export const AttendancePage = () => {
  const { getUser, user } = useUsers();
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const [initValues, setInitValues] = useState({});
  const [refetchTable, setRefetchTable] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState({});
  const [attendanceUserList, setAttendanceUserList] = useState([]);
  const [submenu, setSubmenu] = useState(null);
  const [autocompleteData, setAutocompleteData] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const [isCopyMode, setIsCopyMode] = useState(false);
  const [canEdit, setCanEdit] = useState(true);
  const [selectedRow, setSelectedRow] = useState({
    id: [],
  });

  useEffect(() => {
    getUser();
    getMenus();

    const def = {
      date: dayjs(new Date()),
      projectId: null,
      statusId: null,
    };

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          statusId: autocompleteData?.status?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          status: autocompleteData?.status?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('attendance');
    setSubmenu(submenuId);
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (submenu) initDefaultValues();
  }, [submenu]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
        setAttendanceUserList(
          selectedValues?.attendanceUser?.map((user) => ({
            ...user,
            project: selectedValues.project,
          })) ?? []
        );
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const updateBatchedStatuses = (values) => {
    const updData = {
      statusId: values.statusId,
      selectedRows: selectedRow.id,
    };
    attendenceService.updateBatchedStatus(updData).then(() => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setSelectedRow({
        id: [],
      });
    });
  };

  const initDefaultValues = () => {
    masterDataService
      .statusToForm(submenu)
      .then((data) => {
        const aData = {
          status: data,
        };
        setAutocompleteData(aData);
      })
      .finally(() => {});
  };

  useEffect(() => {
    setRefetchTable(true);
  }, [isCopyMode]);

  const valuesToForm = () => {
    const { date, projectId, statusId, orderNumber } = selectedValues;

    setInitValues({
      date: dayjs(date),
      projectId,
      statusId,
      orderNumber,
    });
  };

  const validationSchema = Yup.object().shape({
    date: Yup.string().required(),
    projectId: Yup.string().required(),
    statusId: Yup.string().required(),
    orderNumber: Yup.string().nullable(),
  });

  const insertObject = (values) => {
    const insertObject = {
      ...values,
      date: dayjs(values.date).toISOString(),
      userId: user.userId,
      createdBy: user.userId,
      attendanceUser: selectedValues?.attendanceUser,
    };

    attendenceService
      .createAttendence(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
        if (!isCopyMode) {
          setSelectedValues(data);
        }
      })

      .finally(() => {
        //@TODO loader
      });
  };

  const updateObject = (values) => {
    const updData = {
      ...values,
      date: dayjs(values.date).toISOString(),
      updatedBy: user.userId,
    };

    attendenceService.updateAttendence(updData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      insertObject(values, resetForm);
    }
  };

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    attendenceService.deleteAttendence(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultValues);
    });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-6 h-screen gap-2 p-4 bg-gray-100 mt-[-4rem] pt-[5rem]">
      <div className="col-span-1 lg:col-span-1 xl:col-span-3 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="h-full overflow-auto custom-scrollbar">
          <FormHeaderUI
            addClick={() => {
              setSelectedValues(defaultSelectedValues);
              setViewField(false);
              setAddField(false);
              setAttendanceUserList([]);
            }}
            addDisabled={!viewField || !canEdit}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled || !canEdit}
            deleteClick={() => {
              setShowDeleteDialog(true);
            }}
            deleteDisabled={!viewField || operatorsDisabled || !canEdit}
            copyClick={() => {
              setViewField(false);
              setAddField(false);
              setIsCopyMode(true);
            }}
            copyDisabled={!viewField || operatorsDisabled || !canEdit}
          />

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, errors, resetForm, validateField, setFieldValue }) => (
              <Form>
                <AttendanceField
                  viewField={viewField}
                  selectedValues={selectedValues}
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  validateField={validateField}
                  submenuId={submenu}
                  selectedRow={selectedRow}
                />
                <AttendanceTabs
                  attendance={{ attendanceUserList, setAttendanceUserList }}
                  setRefetchTable={setRefetchTable}
                  id={selectedValues.id}
                  user={user}
                  disabled={viewField}
                />
                {(!editButtonVisible || !addField) && (
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      resetForm();
                    }}
                  />
                )}
                {selectedRow.id.length > 0 && (
                  <div className="flex justify-center">
                    <ButtonUI
                      className="bg-success"
                      text="rögzítés"
                      onClick={() => {
                        updateBatchedStatuses(values);
                      }}
                    />
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="col-span-1 lg:col-span-1 xl:col-span-3 overflow-hidden bg-white shadow sm:rounded-lg  ">
        <div className="h-full overflow-auto custom-scrollbar">
          <AttendanceReports
            refetch={{ refetchTable, setRefetchTable }}
            values={{ selectedValues, setSelectedValues }}
            viewField={viewField}
            operatorsDisabled={operatorsDisabled}
            submenuId={submenu}
            selectedRows={{ selectedRow, setSelectedRow }}
            defaultValues={defaultValues}
            editButtonVisible={editButtonVisible}
          />
        </div>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </div>
  );
};
